import type { Duration } from 'date-fns';
import {
  addDays,
  addMonths,
  compareAsc,
  endOfDay,
  format,
  formatDuration,
  formatISODuration,
  intervalToDuration,
  isAfter,
  parseISO,
  startOfDay,
  subDays,
  subMonths,
} from 'date-fns';
// eslint-disable-next-line import/no-extraneous-dependencies
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import parse from 'postgres-interval';

export function capitalizeFirstLetter(text: string | null) {
  if (!text) {
    return text;
  }

  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function displayRelativeTime(dateTime: Date | string) {
  let dateTimeObject = dateTime;
  if (typeof dateTime === 'string') {
    dateTimeObject = new Date(dateTime);
  }
  const today = new Date();
  const duration = intervalToDuration({
    start: dateTimeObject as Date,
    end: today,
  });

  const agoWrapper = (value: string) => `${value} ago`;
  const inWrapper = (value: string) => `in ${value}`;

  if (duration.years || duration.months) {
    return format(dateTimeObject as Date, 'MMM d, yyyy');
  }

  if (duration?.days && duration.days < 0) {
    return inWrapper(
      formatDuration({ days: Math.abs(duration.days) }, { format: ['days'] })
    );
  }

  if (duration?.hours && duration.hours < 0) {
    return `${Math.abs(duration.hours)}h`;
  }

  if (duration?.minutes && duration.minutes < 0) {
    return `${Math.abs(duration.minutes)}m`;
  }

  if (duration?.days && duration.days >= 7) {
    return agoWrapper(
      formatDuration(
        { weeks: Math.floor(duration.days / 7) },
        { format: ['weeks'] }
      )
    );
  }

  if (duration.days) {
    return agoWrapper(formatDuration(duration, { format: ['days'] }));
  }

  if (duration.hours) {
    return `${duration.hours}h`;
  }

  if (duration.minutes) {
    return `${duration.minutes}m`;
  }

  return 'Now';
}

export function displayRelativeTimeShort(
  dateTime: Date,
  useShortFormat: boolean = true
) {
  const today = new Date();
  const duration = intervalToDuration({ start: dateTime, end: today });

  if (duration?.years) {
    if (duration.years === 1) {
      return useShortFormat ? '1y' : '1 year';
    }
    return useShortFormat ? `${duration.years}y` : `${duration.years} years`;
  }

  if (duration?.months) {
    if (duration.months === 1) {
      return useShortFormat ? '1mo' : '1 month';
    }
    return useShortFormat
      ? `${duration.months}mo`
      : `${duration.months} months`;
  }

  if (duration?.days && duration.days >= 7) {
    if (duration.days === 7) {
      return useShortFormat ? '1w' : '1 week';
    }
    return useShortFormat
      ? `${Math.floor(duration.days / 7)}w`
      : `${Math.floor(duration.days / 7)} weeks`;
  }

  if (duration.days) {
    if (duration.days === 1) {
      return useShortFormat ? '1d' : '1 day';
    }
    return useShortFormat ? `${duration.days}d` : `${duration.days} days`;
  }

  return null;
}

export function displayFrequency(frequency: Duration | null) {
  if (
    frequency?.days === 7 &&
    !frequency?.hours &&
    !frequency?.minutes &&
    !frequency?.weeks &&
    !frequency?.months &&
    !frequency?.years
  ) {
    return 'Every week';
  }
  if (
    frequency?.days === 14 &&
    !frequency?.hours &&
    !frequency?.minutes &&
    !frequency?.weeks &&
    !frequency?.months &&
    !frequency?.years
  ) {
    return 'Every 2 weeks';
  }
  if (frequency) {
    return `Every ${formatDuration(frequency).replace(/^1\s?/, '')}`;
  }
  return `Without Frequency`;
}

export async function promiseAllObject<
  Values extends { [key: string]: unknown }
>(promisesObject: {
  [P in keyof Values]: PromiseLike<Values[P]>;
}): Promise<Values> {
  return Promise.all(
    Object.entries(promisesObject).map(([key, prom]) =>
      prom.then((value) => [key, value])
    )
  ).then((entries) => Object.fromEntries(entries) as Values);
}

export function formatDateString(dateString: string | number | Date) {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
}

export function customFormatISODuration(frequency: Duration | null) {
  if (frequency) {
    return formatISODuration(frequency);
  }

  return 'null';
}

export function newDateFromPlainDateString(date: string | Date): Date {
  // If it's already a date, return it
  if (date instanceof Date) return date;
  // If it's not a string, return it
  if (typeof date !== 'string' || !date.includes('-')) return new Date(date);

  const newDate = new Date();
  const [year, month, possibleDay] = date.split('-');
  let day;
  let hours;
  let minutes;

  if (possibleDay?.includes('T')) {
    [day, hours] = possibleDay.split('T');

    [hours, minutes] = hours.split(':');
  } else {
    day = possibleDay;
  }

  newDate.setFullYear(Number(year), Number(month) - 1, Number(day));
  newDate.setHours(0, 0, 0);

  if (hours) {
    newDate.setHours(Number(hours), Number(minutes));
  }

  return newDate;
}

export function getSameDateInCurrentYear(date: string): Date {
  const returnDate = newDateFromPlainDateString(date);
  const today = new Date();
  const currentYear = today.getFullYear();

  returnDate.setFullYear(currentYear);

  return returnDate;
}

export function generateMailtoLink(
  email?: string,
  subject?: string,
  body?: string
) {
  let link = 'mailto:';

  // Check if email is not undefined, then add it to the link
  if (email) {
    link += encodeURIComponent(email);
  }

  link += '?';

  // Check if subject is not undefined, then add it to the link
  if (subject) {
    link += `subject=${encodeURIComponent(subject)}&`;
  }

  // Check if body is not undefined, then add it to the link
  if (body) {
    link += `body=${encodeURIComponent(body)}`;
  }

  return link;
}

export function newPlainStringDate(date?: Date): string {
  if (!date) {
    return format(new Date(), 'yyyy-MM-dd');
  }

  return format(date, 'yyyy-MM-dd');
}

export function stripHtmlToText(html): string {
  if (!html) return '';

  let htmlCopy = html;

  // remove code brakes and tabs
  htmlCopy = htmlCopy.replace(/\n/g, '');
  htmlCopy = htmlCopy.replace(/\t/g, '');

  // keep html brakes and tabs
  htmlCopy = htmlCopy.replace(/<\/td>/g, '\t');
  htmlCopy = htmlCopy.replace(/<\/table>/g, '\n');
  htmlCopy = htmlCopy.replace(/<\/tr>/g, '\n');
  htmlCopy = htmlCopy.replace(/<\/p>/g, '\n');
  htmlCopy = htmlCopy.replace(/<\/div>/g, '\n');
  htmlCopy = htmlCopy.replace(/<\/h>/g, '\n');
  htmlCopy = htmlCopy.replace(/<br>/g, '\n');
  htmlCopy = htmlCopy.replace(/<br( )*\/>/g, '\n');

  // Remove <style> tags
  htmlCopy = htmlCopy.replace(/<style([\s\S]*?)<\/style>/gi, '');

  // parse html into text
  let dom;
  if (html.includes('<!doctype')) {
    dom = new DOMParser().parseFromString(htmlCopy, 'text/html');
  } else {
    dom = new DOMParser().parseFromString(
      `<!doctype html><body>${htmlCopy}`,
      'text/html'
    );
  }

  return dom.body?.textContent || '';
}

export function formatLastInteractionText(lastInteraction?: string | null) {
  const addSuffix = (displayTime: string | null) => {
    if (displayTime?.includes('-')) {
      return `in ${displayTime?.split('-')[1]}`;
    }
    if (displayTime) {
      return `${displayTime} ago`;
    }
    return 'today';
  };
  return lastInteraction
    ? addSuffix(displayRelativeTimeShort(new Date(lastInteraction)))
    : null;
}

export function delay(ms: number) {
  return new Promise((resolve) => {
    // eslint-disable-next-line no-promise-executor-return
    return setTimeout(resolve, ms);
  });
}

export function isFrequencyValueLessThanMinimum(
  minimumFrequency: unknown,
  frequency: Duration
) {
  const minFreqObject = parse(minimumFrequency as string);
  // check if there exists years in freq object and if yes - compare and return accordingly
  if (frequency.years && frequency.years <= minFreqObject.years) {
    return false;
  }
  // we're now sure that freq is not in years, so here we check if min freq is in years, if yes,
  // freq is going to be less than minFreq, so return false
  if (minFreqObject.years > 0) {
    return false;
  }
  // since now both freq and minFreq are in months, we can compare and return accordingly
  if (frequency.months && frequency.months <= minFreqObject.months) {
    return false;
  }

  if (frequency.days && !minFreqObject.days) {
    return false;
  }

  if (
    frequency.days &&
    minFreqObject.days &&
    frequency.days <= minFreqObject.days
  ) {
    return false;
  }
  return true;
}

export function formatGoogleAutoCompleteAddressObject(
  formatedAddressString,
  addressObject
) {
  // Initialize the formatted address object with default values.
  const formattedAddressObject = {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    completeAddressString: '',
  };

  formattedAddressObject.street1 =
    formatedAddressString?.split(',')[0]?.trim() || '';

  // Process address components.
  addressObject.forEach((addressComponent) => {
    if (addressComponent.types.includes('locality')) {
      formattedAddressObject.city = addressComponent.long_name;
    }
    if (addressComponent.types.includes('administrative_area_level_1')) {
      formattedAddressObject.state = addressComponent.short_name;
    }
    if (addressComponent.types.includes('postal_code')) {
      formattedAddressObject.zip = addressComponent.short_name;
    }
  });

  if (formattedAddressObject.city === '') {
    const administrativeLevel2 = addressObject?.filter((eachObject) =>
      eachObject.types.includes('administrative_area_level_2')
    )[0];
    formattedAddressObject.city = administrativeLevel2.long_name;
  }
  formattedAddressObject.completeAddressString = formatedAddressString;

  return formattedAddressObject;
}

export function isDateInNextMonth(dateString: string) {
  return (
    compareAsc(
      getSameDateInCurrentYear(dateString),
      addMonths(newDateFromPlainDateString(format(new Date(), 'yyyy-MM-dd')), 1)
    ) < 0 &&
    compareAsc(
      getSameDateInCurrentYear(dateString),
      newDateFromPlainDateString(format(new Date(), 'yyyy-MM-dd'))
    ) >= 0
  );
}

export function isDateInNextSevenDays(dateString: string) {
  return (
    compareAsc(
      getSameDateInCurrentYear(dateString),
      addDays(newDateFromPlainDateString(format(new Date(), 'yyyy-MM-dd')), 7)
    ) < 0 &&
    compareAsc(
      getSameDateInCurrentYear(dateString),
      newDateFromPlainDateString(format(new Date(), 'yyyy-MM-dd'))
    ) >= 0
  );
}

export function isDateInPreviousMonth(dateString: string) {
  return (
    compareAsc(
      getSameDateInCurrentYear(dateString),
      subMonths(newDateFromPlainDateString(format(new Date(), 'yyyy-MM-dd')), 1)
    ) > 0 &&
    compareAsc(
      getSameDateInCurrentYear(dateString),
      newDateFromPlainDateString(format(new Date(), 'yyyy-MM-dd'))
    ) < 0
  );
}

export function extractValidEmails(inputString: string | undefined | null) {
  // Regular expression to match valid email addresses
  const emailRegex = /[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,4}/g;

  if (inputString) {
    // Use the regular expression to find all email matches in the input string
    const emailMatches = inputString.match(emailRegex);

    // If there are valid email matches, return them in an array; otherwise, return undefined
    if (emailMatches && emailMatches.length > 0) {
      return emailMatches;
    }
  }

  return undefined;
}

export function getCurrentDateBoundsInTimeZone(
  date: Date,
  timeZone: string
): {
  startOfDayEpoch: number;
  endOfDayEpoch: number;
} {
  // "Now" in your system's local time
  const now = new Date(date);

  // Convert that moment to the same exact moment in the given time zone
  const zonedNow = toZonedTime(now, timeZone);

  // Get "local" start/end of the day in that time zone (still a Date in JS, but 'shifted')
  const zonedStartOfDay = startOfDay(zonedNow);
  const zonedEndOfDay = endOfDay(zonedNow);

  // Convert these "zoned" midnight boundaries back to UTC-based Date objects,
  // so that .getTime() will give correct epoch milliseconds
  const utcStartOfDay = fromZonedTime(zonedStartOfDay, timeZone);
  const utcEndOfDay = fromZonedTime(zonedEndOfDay, timeZone);

  return {
    startOfDayEpoch: toUnix(utcStartOfDay), // milliseconds since epoch
    endOfDayEpoch: toUnix(utcEndOfDay), // milliseconds since epoch
  };
}

export function validateEmail(email: string) {
  if (email.length < 3) {
    return false;
  }
  // Count the occurrences of '@' and '.'
  const atCount = (email.match(/@/g) || []).length;
  const dotCount = (email.match(/\./g) || []).length;

  // Check if there is exactly one '@' and at least one '.'
  if (atCount === 1 && dotCount >= 1) {
    // Check if '.' comes after '@'
    if (email.indexOf('@') < email.lastIndexOf('.')) {
      return true;
    }
  }
  return false;
}

export function toIsoString(ds: string | undefined): string | null {
  if (!ds) {
    return null;
  }
  const date = new Date(ds);
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
}

/**
 * Checks if a given date is within the last week from today.
 *
 * @param {Date | string} date - The date to check, can be a Date object or an ISO string.
 * @returns {boolean} - Returns true if the date is within the last week, otherwise false.
 */
export function isInLastWeek(date: Date | string): boolean {
  const today = new Date();
  const oneWeekAgo = subDays(today, 7);

  // Parse the date if it is a string
  const inputDate = typeof date === 'string' ? parseISO(date) : date;

  // Check if the date is between one week ago and today
  return isAfter(inputDate, oneWeekAgo);
}

/**
 * Converts the provided Date to a Unix timestamp.
 * @param d Date to convert.
 */
export function toUnix(d: Date): number {
  return Math.floor(d.getTime() / 1000);
}
